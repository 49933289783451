.custom-mobile-app-silder {
  padding: 0 50px;
}
.slick-dots li button:before {
  font-size: 16px;
}
.slick-dots li.slick-active button:before {
  color: #7635dc;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  color: #7635dc;
}

.slick-dots {
  position: initial;
}

.heading-banner {
  position: relative;
  color: #7635dc;
  font-family: 'Bebas Neue';
  font-weight: 100;
  font-size: 100px;
  -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 0.4px;
  -webkit-text-stroke-color: #ffffff;
  align-self: center;
}

.hvr-sweep-to-right {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  color: #7635dc;
}

.hvr-sweep-to-right:hover {
  color: #ffffff;
}

.hvr-sweep-to-right:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #7635dc;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  border-radius: 8px;
}

.hvr-sweep-to-right:hover:before,
.hvr-sweep-to-right:focus:before,
.hvr-sweep-to-right:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

::-webkit-scrollbar {
  display: none;
}
